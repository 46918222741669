<messages>["./BePassword"]</messages>

<template>
  <base-layout mw2>
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div
              v-t="'label.title'"
              class="text-h5"/>
            <div
              v-t="'label.subTitle'"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <template v-if="loading">
              <v-col cols="12">
                <v-progress-linear indeterminate/>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="registrar"
                  clearable
                  spellcheck="false"
                  :items="registrars"
                  :label="$t ('label.header.registrar')"/>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="registry"
                  clearable
                  spellcheck="false"
                  :items="registries"
                  :label="$t ('label.header.registry')"/>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                  v-model="state"
                  clearable
                  :items="states"
                  :label="$t ('label.header.state')"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model.trim="usage"
                  clearable
                  :label="$t ('label.header.usage')"/>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  class="elevation-1"
                  must-sort
                  :headers="headers"
                  :items="passwords"
                  :footer-props="footerProps"
                  :no-data-text="noDataText (loading)">
                  <template #item="props">
                    <tr>
                      <td class="text-left">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-icon :color="getStateColor (props.item.state)" v-on="on">
                              {{ getIcon (props.item.state) }}
                            </v-icon>
                          </template>
                          <span>{{ getText (props.item.state) }}</span>
                        </v-tooltip>
                      </td>
                      <td class="text-left">
                        <span class="password">
                          {{ props.item.password }}
                        </span>
                      </td>
                      <td class="text-left">
                        {{ props.item.registrar }}
                      </td>
                      <td class="text-left">
                        {{ props.item.registry }}
                      </td>
                      <td class="text-left">
                        {{ props.item.usage }}
                      </td>
                      <td class="text-left">
                        {{ formatDate (props.item.validFrom) }}
                      </td>
                      <td class="text-left">
                        {{ formatDate (props.item.validTo) }}
                      </td>
                      <td class="text-left">
                        {{ formatDate (props.item.renew) }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapActions} from 'vuex'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'

  import BaseLayout from '@/app/core/components/BaseLayout'

  export default {
    components: {
      BaseLayout
    },

    mixins: [paginationMixins],

    data () {
      return {
        loading: false,
        loadedPasswords: [],
        registry: undefined,
        registrar: undefined,
        state: null,
        usage: ''
      }
    },

    computed: {
      passwords () {
        return this.loadedPasswords
          ? this.loadedPasswords.filter ((item) => {
            if (this.registry && item.registry !== this.registry) {
              return false
            }

            if (this.registrar && item.registrar !== this.registrar) {
              return false
            }

            if (this.state && item.state !== this.state) {
              return false
            }

            return !(this.usage && this.usage !== '' && !item.usage.includes (this.usage))
          })
          : []
      },

      states () {
        return ['active', 'retired', 'reserve'].map ((item, idx) => ({
          value: idx + 1,
          text: this.$t (`label.states.${item}`)
        }))
      },

      headers () {
        return [
          {
            text: this.$t ('label.header.state'),
            value: 'state'
          },
          {
            text: this.$t ('label.header.password'),
            value: 'password'
          },
          {
            text: this.$t ('label.header.registrar'),
            value: 'registrar'
          },
          {
            text: this.$t ('label.header.registry'),
            value: 'registry'
          },
          {
            text: this.$t ('label.header.usage'),
            value: 'usage'
          },
          {
            text: this.$t ('label.header.validFrom'),
            value: 'validFrom'
          },
          {
            text: this.$t ('label.header.validTo'),
            value: 'validTo'
          },
          {
            text: this.$t ('label.header.renew'),
            value: 'renew'
          }
        ]
      },

      registries () {
        const reg = []

        if (this.loadedPasswords) {
          this.loadedPasswords.forEach (({registry}) => {
            if (!reg.includes (registry) && registry) {
              reg.push (registry)
            }
          })
        }

        reg.sort ()
        return reg
      },

      registrars () {
        const reg = []

        if (this.loadedPasswords) {
          this.loadedPasswords.forEach (({registrar}) => {
            if (!reg.includes (registrar) && registrar) {
              reg.push (registrar)
            }
          })
        }

        reg.sort ()
        return reg
      }
    },

    created () {
      this.loadPasswords ()
      this.pagination.sortBy = 'state'
      this.pagination.rowsPerPage = 10
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadPasswords () {
        this.loading = true

        this.fetchData ({
          op: 'admin/load-be-passwords',
          cb: ({data}) => {
            this.loadedPasswords = data
          },
          cbFinal: () => {
            this.loading = false
          }
        })
      },

      getStateColor (state) {
        switch (state) {
          case 1:
            return 'success'
          case 2:
            return 'error'
          case 3:
            return 'waring'
        }
      },

      getIcon (state) {
        switch (state) {
          case 1:
            return 'check_circle'
          case 2:
            return 'highlight_off'
          case 3:
            return 'pan_tool'
        }
      },

      getText (state) {
        switch (state) {
          case 1:
            return this.$t ('label.states.active')
          case 2:
            return this.$t ('label.states.retired')
          case 3:
            return this.$t ('label.states.reserve')
        }
      }
    }
  }
</script>

<style scoped>
.password {
  font-family: monospace;
  word-wrap: none;
  white-space: nowrap;
}
</style>
